.home-page-wrapper {
  .home-page-content {
    background: #fff;
    box-shadow: 0px -2px 6.7px 0.3px rgba(0, 0, 0, 0.1);
    position: relative;

    .home-cta-wrapper {
      display: flex;
      justify-content: center;
      margin: 75px 0 0;

      @media screen and (max-width: 767px) {
        margin: 40px 0 0;
      }

      .home-cta-btn {
        background-color: #16365a;
        border-radius: 4px;
        box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.14);
        color: rgba(255, 255, 255, 0.9);
        font-family: "SoraMedium";
        font-size: 16px;
        padding: 16px 50px;
        text-align: center;
        text-decoration: none;
        transition: 0.3s;

        &:hover {
          background: #475b71;
          transition: 0.3s;
        }

        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
      }
    }

    .homepage-nft-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: -10px;

      .nft-item {
        max-width: 315px;
        min-width: 315px;
      }
    }

    .info-section {
      align-items: flex-start;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 auto;
      max-width: 1300px;

      .item {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin: 0 50px 30px 50px;
        max-width: 330px;

        @media screen and (max-width: 600px) {
          margin: 0 0 30px;
        }

        .item-image {
          height: 50px;
          margin-bottom: 15px;

          @media screen and (max-width: 767px) {
            height: 40px;
          }

          img {
            height: 100%;
            width: auto;
          }
        }

        .item-title {
          color: #11273f;
          font-family: "SoraMedium";
          font-size: 24px;
          line-height: 1.4;
          letter-spacing: -0.24px;
          margin-bottom: 10px;
          text-align: center;

          @media screen and (max-width: 767px) {
            font-size: 18px;
          }
        }

        .item-text {
          color: rgba(17, 39, 63, 0.8);
          font-family: "MontserratRegular";
          font-size: 16px;
          letter-spacing: -0.16px;
          line-height: 1.5;
          text-align: center;

          @media screen and (max-width: 767px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}

section {
  padding: 120px 20px;

  @media screen and (max-width: 991px) {
    padding: 80px 20px;
  }

  @media screen and (max-width: 767px) {
    padding: 60px 20px;
  }

  &.grey {
    background: #fbfcfd;
  }

  .section-header {
    h2 {
      color: rgba(17, 39, 63, 0.8);
      font-size: 36px;
      font-family: "SoraRegular";
      letter-spacing: -0.48px;
      line-height: 1.6;
      margin: 0;
      margin-bottom: 20px;
      text-align: center;
      text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);

      @media screen and (max-width: 991px) {
        font-size: 32px;
      }

      @media screen and (max-width: 767px) {
        font-size: 26px;
        margin-bottom: 20px;
      }

      @media screen and (max-width: 450px) {
        font-size: 22px
      }

      b {
        color: rgba(17, 39, 63, 1);
        font-family: "SoraBold";
      }

      &:before {
        background: #c2cbd4;
        border-radius: 2.5px;
        box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.14);
        content: '';
        display: block;
        height: 6px;
        margin: 0 auto 32px;
        width: 55px;

        @media screen and (max-width: 767px) {
          height: 4px;
          margin: 0 auto 22px;
          width: 45px;
        }
      }
    }

    h3 {
      color: rgba(17, 39, 63, 0.5);
      font-family: "MontserratRegular";
      font-size: 18px;
      letter-spacing: -0.18px;
      line-height: 1.44;
      margin: 0 auto;
      max-width: 650px;
      text-align: center;
      text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);

      @media screen and (max-width: 767px) {
        font-size: 16px;
      }

      @media screen and (max-width: 450px) {
        font-size: 14px;
      }
    }
  }

  .section-content {
    max-width: 1340px;
    margin: 0 auto;

    &.mt-40 {
      margin-top: 40px;
    }

    &.mt-80 {
      margin-top: 80px;
    }
  }
}


.gallery-page {
  display: flex;

  .gallery-content-area {
    padding: 25px 15px 0 15px;
    width: 100vw;

    @media screen and (max-width: 767px) {
      .main-group {
        display: none;
      }
    }
  }
}