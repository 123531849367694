.terms-of-use,
.privacy-policy,
.cookie-policy {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 130px auto 80px;
  max-width: 1340px;
  padding: 0 20px;
  width: calc(100% - 40px);

  @media screen and (max-width: 767px) {
    margin: 100px auto 80px;
  }

  h2 {
    color: rgba(17, 39, 63, 0.8);
    font-size: 36px;
    font-family: "SoraRegular";
    letter-spacing: -0.48px;
    line-height: 1.6;
    margin: 0;
    margin-bottom: 20px;
    text-align: center;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);

    @media screen and (max-width: 991px) {
      font-size: 32px;
    }

    @media screen and (max-width: 767px) {
      font-size: 26px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 450px) {
      font-size: 22px
    }

    &:before {
      background: #c2cbd4;
      border-radius: 2.5px;
      box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.14);
      content: '';
      display: block;
      height: 6px;
      margin: 0 auto 32px;
      width: 55px;

      @media screen and (max-width: 767px) {
        height: 4px;
        margin: 0 auto 22px;
        width: 45px;
      }
    }
  }

  .page-content {
    .center-content {
      text-align: center;
    }

    h3 {
      color: rgba(17, 39, 63, 0.7);
      font-family: "SoraBold";
      font-size: 21px;
      margin-top: 30px;
      opacity: 0.9;

      @media only screen and (max-width: 991px) {
        font-size: 16px;
      }
    }

    h4 {
      color: rgba(17, 39, 63, 0.8);
      font-family: "SoraMedium";
      font-size: 16px;
      font-weight: 500;
      opacity: 0.9;
      margin: 5px 0;

      @media only screen and (max-width: 991px) {
        font-size: 14px;
      }
    }

    p {
      color: rgba(17, 39, 63, 0.5);
      font-family: "SoraRegular";
      font-size: 16px;
      line-height: 1.4;

      b {
        color: rgba(17, 39, 63, 0.7);
        font-family: "SoraMedium";
      }

      @media only screen and (max-width: 991px) {
        font-size: 14px;
      }
    }

    a {
      color: #16365a;
      font-weight: 500;
      transition: 0.3s;

      &:hover {
        opacity: 0.8;
        transition: 0.3s;
      }
    }

    ul {
      li {
        color: rgba(17, 39, 63, 0.5);
        font-family: "SoraRegular";
        font-size: 14px;
        line-height: 1.4;

        @media only screen and (max-width: 991px) {
          font-size: 12px;
        }

        &::marker {
          color: #16365a;
        }
      }
    }
  }
}