.gallery-filters-wrapper {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 5px;

  @media screen and (max-width: 1380px) {
    align-items: flex-start;
    flex-direction: column;
  }

  @media screen and (max-width: 767px) {
    margin: 0;
  }

  .group {
    align-items: center;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    width: 100%;

    @media screen and (max-width: 767px) {
      margin-bottom: 10px;

      &.main-group {
        border-bottom: 1px solid rgba(207, 217, 221, 0.6);
        margin: 0 0 10px 0;
        padding: 0 0 15px 0;

        @media screen and (max-width: 450px) {
          flex-direction: column;
          width: 100%;

          .tab {
            margin-left: 0;
            margin-right: 0;
            width: calc(100% - 10px);
          }
        }
      }
    }

    @media screen and (max-width: 450px) {
      flex-direction: column;

      .filter {
        width: calc(100% - 10px);
      }

      .selectbox {
        width: calc(100% - 60px);
      }
    }
  }

  .selectbox-wrapper {
    margin: 5px;

    @media screen and (max-width: 450px) {
      width: 100%;
    }
  }

  .tab {
    align-items: center;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    color: #16375b;
    display: flex;
    font-family: "SoraMedium";
    font-size: 14px;
    text-align: center;
    height: 45px;
    justify-content: center;
    margin: 5px;
    transition: 0.3s;
    white-space: nowrap;
    width: 160px;

    @media screen and (max-width: 991px) {
      font-size: 12px;
      width: 130px;
    }

    &:hover {
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.4);
      cursor: pointer;
      transition: 0.3s;
    }

    svg {
      margin-right: 8px;
    }

    .count {
      margin-left: 8px;
    }

    &.active {
      background: #16365a;
      color: rgba(255, 255, 255, 0.9);
      transition: 0.3s;
    }
  }

  .item-counter {
    color: #16375b;
    font-size: 14px;
  }

  .filter {
    display: flex;
    flex: 1;
    margin: 5px;
    position: relative;

    .item-count {
      align-items: center;
      color: #16375b;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      justify-content: center;
      margin-right: 10px;
      text-align: center;

      span {
        font-size: 10px;
        opacity: 0.6;
      }
    }

    svg {
      opacity: 0.3;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);

      @media screen and (max-width: 991px) {
        font-size: 12px;
      }
    }

    input {
      align-items: center;
      background: #dce1e7;
      border: 1px solid #dce1e7;
      border-radius: 4px;
      color: #11273f;
      display: flex;
      font-family: "MontserratRegular";
      font-size: 14px;
      height: 43px;
      max-width: 100%;
      min-width: 200px;
      padding: 0 45px 0 20px;
      transition: 0.3s;
      width: 100%;

      @media screen and (max-width: 991px) {
        font-size: 12px;
      }

      @media screen and (max-width: 767px) {
        min-width: auto;
      }

      &:focus,
      &:focus-visible {
        border: 1px solid rgba(17, 39, 63, 0.2);
        outline: none;
        transition: 0.3s;
      }

      &::placeholder {
        opacity: 0.8;
      }
    }
  }
}