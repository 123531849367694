.trending-today {
  .slide {
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    justify-content: center;
  }

  .carousel-status {
    display: none;
  }

  .carousel {
    overflow: visible;

    @media only screen and (max-width: 991px) {
      margin-bottom: 40px;
    }

    .control-dots {
      bottom: -60px;

      .dot {
        background: rgba(0, 0, 0, 0.6);
        box-shadow: none;
        margin: 0 4px;
      }
    }

    .control-arrow {
      align-items: center;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      display: flex;
      height: 50px;
      justify-content: center;
      top: 50%;
      transform: translateY(-50%);
      width: 50px;

      &:hover {
        background: rgba(0, 0, 0, 0.6);
      }
    }
  }

  .nft-item-wrapper {
    max-width: 315px;
    max-height: 448px;
  }
}

