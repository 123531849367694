.profile-menu {
  align-items: center;
  display: flex;
  height: 100%;
  margin-left: 5px;
  position: relative;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    height: auto;
    margin-left: 0;
    width: 100%;
  }

  &:hover {
    cursor: pointer;
  }

  .profile-image {
    border-radius: 50%;
    box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.15);
    height: 50px;
    max-height: 50%;
    min-height: 50px;
    overflow: hidden;
    width: 50px;

    @media screen and (max-width: 767px) {
      display: none;
    }

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .mobile-profile-menu-toggle {
    align-items: center;
    border-bottom: 1px solid rgba(207, 217, 221, 0.6);
    color: #11273f;
    display: none;
    flex: 1;
    font-size: 12px;
    justify-content: space-between;
    padding: 12px 20px;
    text-align: center;
    text-decoration: none;
    transition: 0.3s;
    width: calc(100% - 40px);

    @media screen and (max-width: 767px) {
      display: flex;
    }

    svg {
      display: flex;
      font-size: 16px;
      margin-left: 4px;
      transform: rotate(90deg);
    }

    &.active {
      svg {
        transform: rotate(-90deg);
      }
    }
  }

  .profile-menu-dropdown {
    background: #fff;
    border-radius: 0 0 4px 4px;
    border-top: 1px solid rgba(207, 217, 221, 0.6);
    box-shadow: 0px 3px 5px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 100%;
    width: 160px;
    z-index: 999;

    @media screen and (max-width: 767px) {
      background: transparent;
      border-radius: 0;
      border-top: none;
      box-shadow: none;
      position: relative;
      right: auto;
      top: auto;
      width: 100vw;
    }

    .dropdown-item {
      align-items: center;
      border-bottom: 1px solid rgba(207, 217, 221, 0.6);
      border-radius: 0 !important;
      display: flex;
      flex: 1;
      margin: 0 !important;
      user-select: none;
      padding: 16px 20px !important;
      text-decoration: none;
      transition: 0.3s;

      @media screen and (max-width: 767px) {
        background: #fbfcfd;
        border-bottom: 1px solid rgba(207, 217, 221, 0.6);
        padding: 12px 20px !important;
        width: calc(100% - 40px) !important;
      }

      &:hover {
        background: #f9f9f9;
        cursor: pointer;
        transition: 0.3s;

        @media screen and (max-width: 767px) {
          background: transparent;
        }
      }

      &:last-child {
        border-bottom: none;

        @media screen and (max-width: 767px) {
          border-bottom: 1px solid rgba(207, 217, 221, 0.6);
        }
      }

      .icon {
        display: flex;
        margin-right: 10px;

        svg {
          fill: #11273f;
          font-size: 20px;

          @media screen and (max-width: 767px) {
            font-size: 16px;
          }
        }
      }

      .label {
        align-items: center;
        font-size: 14px;
        color: #11273f;
        display: flex;

        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }
    }
  }
}