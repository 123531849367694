.profile-page {
  .upper-profile-page-wrapper {
    align-items: center;
    background: #fbfcfd;
    border-bottom: 1px solid #cfd9dd;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 450px;
    overflow: hidden;
    position: relative;

    @media screen and (max-width: 500px) {
      min-height: 400px;
    }

    .user-info {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      max-width: 600px;
      padding: 40px 20px;
      position: relative;
      text-align: center;
      z-index: 1;

      .user-image {
        border-radius: 50%;
        box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.35);
        height: 125px;
        margin-bottom: 25px;
        width: 125px;

        @media screen and (max-width: 767px) {
          height: 80px;
          margin-bottom: 20px;
          width: 80px;
        }

        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      .user-name {
        color: rgba(17, 39, 63, 0.7);
        font-family: "SoraBold";
        font-size: 30px;
        line-height: 1.4;
        letter-spacing: -0.3px;
        margin-bottom: 4px;
        text-align: center;
        text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);

        @media screen and (max-width: 767px) {
          font-size: 24px;
        }
      }

      .user-date-joined {
        color: rgba(17, 39, 63, 0.5);
        font-size: 16px;
        font-family: "SoraMedium";
        letter-spacing: 0.96px;
        text-align: center;

        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }
    }

    .graphics-wrapper {
      .logo-graphic {
        opacity: 0.15;
        position: absolute;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        z-index: 0;

        &.left {
          animation: leftFloat 6s ease-in-out infinite;
          left: -80px;

          @media screen and (max-width: 1200px) {
            left: -50px;
          }
        }

        &.right {
          animation: rightFloat 6s ease-in-out infinite;
          right: -80px;

          @media screen and (max-width: 1200px) {
            right: -50px;
          }
        }

        @media screen and (max-width: 767px) {
          display: none;
        }

        img {
          height: auto;
          width: 160px;

          @media screen and (max-width: 1200px) {
            width: 100px;
          }
        }

        @keyframes leftFloat {
          0% {
            transform: translate3d(0, -50%, 0);
          }
          50% {
            top: 50%;
            transform: translate3d(-30px, -50%, 0);
          }
          100% {
            top: 50%;
            transform: translate3d(0, -50%, 0);
          }
        }

        @keyframes rightFloat {
          0% {
            transform: translate3d(0, -50%, 0);
          }
          50% {
            top: 50%;
            transform: translate3d(30px, -50%, 0);
          }
          100% {
            top: 50%;
            transform: translate3d(0, -50%, 0);
          }
        }
      }

      .circle-graphic {
        border-radius: 50%;
        position: absolute;
        z-index: 0;

        &.gr-1 {
          animation: gr1Float 6s ease-in-out infinite;
          background: linear-gradient(to bottom, #16375b, #fff);
          bottom: -360px;
          height: 420px;
          left: 8%;
          opacity: 0.04;
          width: 420px;

          @media screen and (max-width: 1440px) {
            bottom: -220px;
            height: 240px;
            width: 240px;
          }

          @media screen and (max-width: 500px) {
            bottom: -220px;
            height: 300px;
            left: -60px;
            width: 300px;
          }
        }

        &.gr-2 {
          animation: gr2Float 8s ease-in-out infinite;
          background: linear-gradient(to bottom, #16375b, #fff);
          bottom: -500px;
          height: 620px;
          left: 22%;
          opacity: 0.06;
          width: 620px;

          @media screen and (max-width: 1440px) {
            bottom: -200px;
            height: 300px;
            width: 300px;
          }

          @media screen and (max-width: 767px) {
            left: 40%
          }

          @media screen and (max-width: 500px) {
            bottom: -80px;
            height: 150px;
            left: calc(100% - 75px);
            width: 150px;
          }
        }

        &.gr-3 {
          animation: gr3Float 8s ease-in-out infinite;
          background: linear-gradient(to bottom, #16375b, #fff);
          height: 670px;
          right: -2%;
          opacity: 0.12;
          top: -70%;
          width: 670px;

          @media screen and (max-width: 767px) {
            right: -20%
          }
        }

        &.gr-4 {
          animation: gr4Float 6s ease-in-out infinite;
          background: linear-gradient(to bottom, #16375b, #fff);
          bottom: -67%;
          height: 420px;
          right: 10%;
          opacity: 0.04;
          width: 420px;

          @media screen and (max-width: 1440px) {
            bottom: -120px;
            height: 240px;
            right: 50px;
            width: 240px;
          }

          @media screen and (max-width: 767px) {
            display: none;
          }
        }

        @keyframes gr1Float {
          0% {
            opacity: 0.04;
            transform: translateY(0px);
          }
          50% {
            opacity: 0.06;
            transform: translate3d(10px, -40px, 0);
          }
          100% {
            opacity: 0.04;
            transform: translateY(0px);
          }
        }

        @keyframes gr2Float {
          0% {
            opacity: 0.06;
            transform: translateY(0px);
          }
          50% {
            opacity: 0.1;
            transform: translate3d(-10px, 40px, 0);
          }
          100% {
            opacity: 0.06;
            transform: translateY(0px);
          }
        }

        @keyframes gr3Float {
          0% {
            opacity: 0.06;
            transform: translateY(0px);
          }
          50% {
            opacity: 0.1;
            transform: translate3d(-10px, 40px, 0);
          }
          100% {
            opacity: 0.06;
            transform: translateY(0px);
          }
        }

        @keyframes gr4Float {
          0% {
            opacity: 0.04;
            transform: translateY(0px);
          }
          50% {
            opacity: 0.06;
            transform: translate3d(10px, -40px, 0);
          }
          100% {
            opacity: 0.04;
            transform: translateY(0px);
          }
        }
      }
    }
  }

  .lower-profile-page-wrapper {
    display: flex;
    position: relative;
  }

  .toolbar-links {
    display: flex;
    left: 50%;
    position: absolute;
    top: -25px;
    transform: translateX(-50%);
    z-index: 3;

    @media screen and (max-width: 991px) {
      top: -72px;
    }

    @media screen and (max-width: 767px) {
      top: -62px;
    }

    a {
      align-items: center;
      background: #fff;
      border: 1px solid #cfd9dd;
      border-right: none;
      color: #16375b;
      display: flex;
      font-size: 20px;
      height: 50px;
      justify-content: center;
      text-decoration: none;
      transition: 0.3s;
      width: 50px;

      @media screen and (max-width: 767px) {
        font-size: 16px;
        height: 40px;
        width: 40px;
      }

      &:first-child {
        border-left: 1px solid #cfd9dd;
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-right: 1px solid #cfd9dd;
        border-radius: 0 4px 4px 0;
      }

      &:only-child {
        border-radius: 4px;
      }

      &:hover {
        background: #f9f9f9;
        transition: 0.3s;
      }
    }
  }

  .profile-content-area {
    padding: 50px 15px 0 15px;
    width: 100vw;

    @media screen and (max-width: 991px) {
      padding: 15px 15px 0 15px;
    }
  }
}