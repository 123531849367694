.nft-list-tabs {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 40px;

  .tab {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    color: #bbb;
    font-family: "SoraBold";
    font-size: 14px;
    line-height: 1.4;
    margin: 5px;
    min-width: 100px;
    padding: 15px 20px;
    text-align: center;
    text-transform: capitalize;
    transition: 0.15s;

    @media screen and (max-width: 767px) {
      font-size: 12px;
      min-width: 75px;
      padding: 12px 16px;
    }

    &:hover {
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
      cursor: pointer;
      transition: 0.15s;
    }

    &.all {
      color: #16375b;

      &.active {
        background: #16375b;
        color: rgba(255, 255, 255, 0.84);
      }
    }

    &.biology {
      color: #afd275;

      &.active {
        background: #afd275;
        color: rgba(255, 255, 255, 0.84);
      }
    }

    &.physics {
      color: #e7717d;

      &.active {
        background: #e7717d;
        color: rgba(255, 255, 255, 0.84);
      }
    }

    &.chemistry {
      color: #8fc1e3;

      &.active {
        background: #8fc1e3;
        color: rgba(255, 255, 255, 0.84);
      }
    }
  }
}