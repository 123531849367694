.nft-list-wrapper {
  .nft-list {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    overflow: auto;
    margin: 0 auto 20px;

    @media screen and (max-width: 2090px) {
      grid-template-columns: repeat(6, 1fr);
    }

    @media screen and (max-width: 1830px) {
      grid-template-columns: repeat(5, 1fr);
    }

    @media screen and (max-width: 1570px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media screen and (max-width: 1310px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 1050px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 500px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .no-results-message {
    background: #fff;
    border: 1px solid #cfd9dd;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 60px 20px;
    text-align: center;
  }
}