.navigation-filter {
  display: flex;
  position: relative;

  @media screen and (max-width: 991px) {
    flex: 1;
  }

  svg {
    font-size: 14px;
    left: 12px;
    opacity: 0.3;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: auto;

    @media screen and (max-width: 991px) {
      font-size: 12px;
    }
  }

  input {
    align-items: center;
    background: #dce1e7;
    border: 1px solid transparent;
    border-radius: 4px;
    color: #11273f;
    display: flex;
    font-family: "MontserratRegular";
    font-size: 14px;
    height: 35px;
    min-width: 185px;
    padding: 0 20px 0 45px;
    transition: 0.3s;

    @media screen and (max-width: 991px) {
      font-size: 12px;
    }

    @media screen and (max-width: 767px) {
      flex: 1;
      min-width: 110px;
      width: 100%;
    }

    &:focus,
    &:focus-visible {
      border: 1px solid rgba(17, 39, 63, 0.2);
      box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.1);
      outline: none;
      transition: 0.3s;
    }

    &::placeholder {
      opacity: 0.8;
    }
  }
}