footer {
  background: #233241;
  box-shadow: 0px -3px 12.5px 0.5px rgba(0, 0, 0, 0.26);
  overflow: hidden;
  position: relative;

  .footer-content-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1340px;
    padding: 60px 20px 80px;
    position: relative;
    z-index: 2;

    @media only screen and (max-width: 991px) {
      padding: 40px 20px;
    }

    .row {
      display: flex;
      flex-wrap: wrap;

      @media only screen and (max-width: 767px) {
        flex-direction: column;
      }
    }

    .column {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin: 20px;
      min-width: 200px;

      &:first-child {
        margin-left: 0;
      }

      @media only screen and (max-width: 910px) {
        flex: 2;
      }

      &.logo-column {
        flex: 2;

        @media only screen and (max-width: 910px) {
          flex: 1;
        }

        @media only screen and (max-width: 767px) {
          margin-bottom: 0;
          margin-left: 20px;
        }
      }

      .column-title {
        align-items: center;
        color: rgba(249, 249, 249, 0.6);
        display: flex;
        font-family: "SoraBold";
        font-size: 20px;
        letter-spacing: 1px;
        margin-bottom: 16px;
        margin-top: 80px;
        text-decoration: none;
        text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
        line-height: 1.5;
        letter-spacing: 1px;

        &:first-child {
          margin-top: 0;
        }

        @media only screen and (max-width: 767px) {
          font-size: 16px;
          margin-bottom: 10px;
        }

        img {
          height: 65px;
          opacity: 0.8;
        }
      }


      .column-item {
        font-family: "SoraBold";
        font-size: 16px;
        margin-bottom: 8px;

        a {
          color: #f9f9f9;
          display: flex;
          font-size: 16px;
          letter-spacing: 0.8px;
          text-decoration: none;
          transition: 0.2s ease-in 0s;

          @media only screen and (max-width: 767px) {
            font-size: 12px;
          }

          &:hover {
            cursor: pointer;
            opacity: 0.9;
            transition: 0.2s ease-in 0s;
          }

          &.email {
            font-family: "SoraRegular";
          }
        }

        &.social-links {
          display: flex;

          a {
            margin-right: 15px;

            svg {
              font-size: 20px;
            }
          }
        }
      }
    }

    .footer-bottom {
      color: rgba(249, 249, 249, 0.84);
      display: flex;
      font-size: 14px;
      text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
      line-height: 1.4;
      letter-spacing: 0.7px;
      justify-content: space-between;
      margin-top: 80px;
      max-width: 800px;
      width: 100%;

      @media only screen and (max-width: 767px) {
        align-items: center;
        flex-direction: column-reverse;
        margin-top: 40px;

        .action-links {
          margin-bottom: 12px;

          a {
            font-size: 12px;
          }
        }
      }

      @media only screen and (max-width: 500px) {
        font-size: 12px;

        .action-links {
          a {
            margin: 0 6px;
          }
        }
      }

      @media only screen and (max-width: 360px) {
        font-size: 11px;
        letter-spacing: 0;

        .action-links {
          a {
            font-size: 11px;
            margin: 0 4px;
          }
        }
      }

      a {
        color: #f9f9f9;
        margin: 0 20px;
        text-decoration: none;
        transition: 0.2s ease-in 0s;

        &:hover {
          color: rgba(249, 249, 249, 1);
          cursor: pointer;
          text-decoration: underline;
          transition: 0.2s ease-in 0s;
        }
      }
    }
  }

  .graphics-wrapper {
    .circle-graphic {
      border-radius: 50%;
      position: absolute;
      z-index: 0;

      &.gr-1 {
        animation: gr1Float 6s ease-in-out infinite;
        background: linear-gradient(to bottom, #16375b, #fff);
        bottom: -100px;
        height: 325px;
        left: -150px;
        opacity: 0.08;
        width: 325px;

        @media screen and (max-width: 1440px) {
          bottom: -120px;
          height: 240px;
          width: 240px;
        }

        @media screen and (max-width: 500px) {
          bottom: -220px;
          height: 300px;
          left: -60px;
          width: 300px;
        }
      }

      &.gr-2 {
        animation: gr2Float 8s ease-in-out infinite;
        background: linear-gradient(to bottom, #16375b, #fff);
        bottom: -240px;
        height: 425px;
        left: 23%;
        opacity: 0.04;
        width: 425px;

        @media screen and (max-width: 1440px) {
          bottom: -150px;
          height: 300px;
          width: 300px;
        }

        @media screen and (max-width: 767px) {
          left: 40%
        }

        @media screen and (max-width: 500px) {
          display: none;
        }
      }

      &.gr-3 {
        animation: gr3Float 8s ease-in-out infinite;
        background: linear-gradient(to bottom, #16375b, #fff);
        bottom: -600px;
        height: 800px;
        left: 30%;
        opacity: 0.12;
        width: 800px;

        @media screen and (max-width: 767px) {
          display: none;
        }
      }

      &.gr-4 {
        animation: gr4Float 6s ease-in-out infinite;
        background: linear-gradient(to bottom, #16375b, #fff);
        bottom: -50px;
        height: 420px;
        right: -100px;
        opacity: 0.04;
        width: 420px;

        @media screen and (max-width: 1440px) {
          bottom: -120px;
          height: 240px;
          right: -100px;
          width: 240px;
        }
      }

      &.gr-5 {
        animation: gr3Float 6s ease-in-out infinite;
        background: linear-gradient(to bottom, #16375b, #fff);
        height: 800px;
        right: -400px;
        opacity: 0.04;
        top: -600px;
        width: 800px;

        @media screen and (max-width: 1440px) {
          display: none;
        }
      }

      @keyframes gr1Float {
        0% {
          opacity: 0.04;
          transform: translateY(0px);
        }
        50% {
          opacity: 0.06;
          transform: translate3d(10px, -40px, 0);
        }
        100% {
          opacity: 0.04;
          transform: translateY(0px);
        }
      }

      @keyframes gr2Float {
        0% {
          opacity: 0.06;
          transform: translateY(0px);
        }
        50% {
          opacity: 0.1;
          transform: translate3d(-10px, 40px, 0);
        }
        100% {
          opacity: 0.06;
          transform: translateY(0px);
        }
      }

      @keyframes gr3Float {
        0% {
          opacity: 0.06;
          transform: translateY(0px);
        }
        50% {
          opacity: 0.1;
          transform: translate3d(-10px, 40px, 0);
        }
        100% {
          opacity: 0.06;
          transform: translateY(0px);
        }
      }

      @keyframes gr4Float {
        0% {
          opacity: 0.04;
          transform: translateY(0px);
        }
        50% {
          opacity: 0.06;
          transform: translate3d(10px, -40px, 0);
        }
        100% {
          opacity: 0.04;
          transform: translateY(0px);
        }
      }
    }
  }
}
