.top-ranking-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1180px) {
    flex-direction: column;
  }

  .top-ranking-column {
    margin-right: 35px;

    @media screen and (max-width: 1180px) {
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    .top-ranking {
      align-items: center;
      border-bottom: 1px solid rgba(207, 217, 221, 0.6);
      display: flex;
      max-width: 350px;
      min-width: 350px;
      padding: 30px 0;
      transition: 0.3s;

      &:hover {
        cursor: pointer;
        opacity: 0.85;
        transform: scale(1.02);
        transition: 0.3s;
      }

      @media screen and (max-width: 767px) {
        max-width: 280px;
        min-width: 280px;
        padding: 25px 0;
      }

      &:last-child {
        border-bottom: none;

        @media screen and (max-width: 1180px) {
          border-bottom: 1px solid rgba(207, 217, 221, 0.6);
        }
      }

      .top-ranking-index {
        color: rgba(17, 39, 63, 0.5);
        font-size: 24px;
        line-height: 1.33;
        margin-right: 35px;
        text-align: center;

        @media screen and (max-width: 767px) {
          margin-right: 25px;
          font-size: 18px;
        }
      }

      .top-ranking-user-image {
        border-radius: 50%;
        box-shadow: 0px 1px 5px 0 rgba(0, 0, 0, 0.4);
        height: 50px;
        margin-right: 20px;
        max-width: 50px;
        min-width: 50px;
        overflow: hidden;
        width: 50px;

        @media screen and (max-width: 767px) {
          height: 35px;
          margin-right: 15px;
          max-width: 35px;
          min-width: 35px;
          width: 35px;
        }

        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      .column {
        display: flex;
        flex: 1;
        flex-direction: column;
      }

      .top-ranking-upper-info {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;

        .top-ranking-user-name {
          color: #11273f;
          font-family: "SoraMedium";
          font-size: 16px;
          margin-right: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 150px;

          @media screen and (max-width: 767px) {
            font-size: 13px;
            width: 120px;
          }
        }

        .top-ranking-percentage-tag {
          color: #4ab244;
          display: flex;
          font-family: "SoraMedium";
          font-size: 12px;

          @media screen and (max-width: 767px) {
            font-size: 10px;
          }

          &.negative {
            color: #eb5757;
          }
        }
      }

      .top-ranking-bottom-info {
        align-items: center;
        color: rgba(17, 39, 63, 0.75);
        display: flex;
        font-family: "SoraBold";
        font-size: 12px;
        justify-content: space-between;

        @media screen and (max-width: 767px) {
          font-size: 10px;
        }

        .top-ranking-previous-price {
          span {
            color: rgba(17, 39, 63, 0.75);
            font-family: "SoraRegular";
            margin-right: 12px;
            opacity: 0.6;

            @media screen and (max-width: 767px) {
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
}