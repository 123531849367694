.file-upload {
  display: flex;
  flex-direction: column;
  position: relative;

  .label-text {
    color: rgba(17, 39, 63, 0.8);
    font-family: "SoraMedium";
    font-size: 18px;
    letter-spacing: -0.2px;
    margin-bottom: 12px;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);

    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }

  label {
    align-items: center;
    background: #fff;
    border-radius: 10px;
    border: 1px dashed #d5d5d5;
    display: flex;
    flex-direction: column;
    min-height: 308px;
    justify-content: center;
    padding: 20px;
    width: calc(100% - 40px);
    word-break: break-all;

    @media screen and (max-width: 991px) {
      min-height: 208px;
    }

    @media screen and (max-width: 767px) {
      min-height: 158px;
    }

    &:hover {
      cursor: pointer;
    }

    p {
      color: rgba(62, 75, 91, 0.5);
      font-family: "SoraMedium";
      font-size: 16px;
      margin: 0;
      text-align: center;

      @media screen and (max-width: 991px) {
        font-size: 14px;
      }

      @media screen and (max-width: 767px) {
        font-size: 12px;
      }

      &:last-child {
        font-family: "SoraRegular";
      }
    }

    svg {
      color: rgba(62, 75, 91, 0.5);
      font-size: 60px;
      margin: 20px 0;

      @media screen and (max-width: 991px) {
        font-size: 40px;
        margin: 15px 0;
      }

      @media screen and (max-width: 767px) {
        font-size: 30px;
      }
    }
  }

  input[type="file"] {
    bottom: 12px;
    cursor: pointer;
    height: calc(100% - 48px);
    left: 0;
    opacity: 0;
    padding: 0 !important;
    position: absolute;
    width: 100%;

    @media screen and (max-width: 767px) {
      height: calc(100% - 42px);
    }
  }
}