.loading-wrapper {
  align-items: center;
  background: #fbfcfd;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 9999;

  h1,
  h2 {
    margin: 0;
    text-align: center;
    margin: 0 1rem;
  }

  h1 {
    @media screen and (max-width: 991px) {
      font-size: 1.8rem;
    }

    @media screen and (max-width: 769px) {
      font-size: 1.3rem;
    }
  }

  h2 {
    font-size: 1.3rem;
    margin-top: 2rem;

    @media screen and (max-width: 991px) {
      font-size: 1.1rem;
    }

    @media screen and (max-width: 769px) {
      font-size: 0.8rem;
    }
  }

  .atom-wrapper {
    align-items: center;
    display: flex;
    height: 200px;
    justify-content: center;
    margin-bottom: 80px;
    position: relative;
    width: 200px;

    @media screen and (max-width: 769px) {
      height: 150px;
      width: 150px;
    }

    .coin {
      animation: fade 4s ease-in-out infinite;
      align-items: center;
      background-color: rgba(17, 39, 63, 1);
      border-radius: 50%;
      display: flex;
      height: 50px;
      justify-content: center;
      opacity: 1;
      width: 50px;
      z-index: 0;

      @media screen and (max-width: 769px) {
        height: 30px;
        width: 30px;
      }

      img {
        opacity: 0.5;
        width: 50%;
      }

      @keyframes fade {
        0%,
        100% {
          opacity: 0.2;
        }
        25%,
        75% {
          opacity: 1;
        }
      }
    }

    &::after,
    &::before {
      animation-duration: 4s, 2s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out, linear;
      border-radius: 50%;
      border: 2px solid rgba(17, 39, 63, 0.8);
      content: "";
      height: 200px;
      left: 50%;
      margin: -101px 0 0 -101px;
      opacity: 1;
      position: absolute;
      top: 50%;
      width: 200px;

      @media screen and (max-width: 769px) {
        height: 150px;
        margin: -76px 0 0 -76px;
        width: 150px;
      }
    }

    &::before {
      animation-name: fade, rotate1;
      z-index: 1;
    }

    &::after {
      animation-name: fade, rotate2;
      z-index: 2;
    }

    @keyframes rotate1 {
      0% {
        transform: perspective(1000px) rotate3d(1, -1, 1, 0deg);
      }
      100% {
        transform: perspective(1000px) rotate3d(1, -1, 1, 360deg);
      }
    }

    @keyframes rotate2 {
      0% {
        transform: perspective(1000px) rotate3d(1, 1, 1, 0deg);
      }
      100% {
        transform: perspective(1000px) rotate3d(1, 1, 1, 360deg);
      }
    }
  }
}
