.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.page-wrapper {
    justify-content: center;
    display: flex;
    margin: 130px auto 80px;
    max-width: 100vw;
    padding: 0 20px;
    width: 95%;
    box-shadow: 0px -2px 6.7px 0.3px rgba(0, 0, 0, 0.1);
    position: relative;

    @media screen and (max-width: 767px) {
      margin: 100px auto 80px;
    }
}