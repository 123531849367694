.signin-page,
.register-page,
.forgot-password-page {
  align-items: center;
  display: flex;
  flex: 1;
  overflow: hidden;
  position: relative;

  .content-wrapper {
    align-items: center;
    display: flex;
    margin: 130px auto 80px;
    max-width: 1340px;
    padding: 0 20px;
    width: 100%;

    @media screen and (max-width: 767px) {
      margin: 100px auto 80px;
    }

    .column {
      display: flex;
      flex: 1;
      flex-direction: column;
      z-index: 2;

      &.negative-margin {
        margin-top: -100px;
      }

      &:first-child {
        margin-right: 160px;

        @media screen and (max-width: 1400px) {
          margin-right: 80px;
        }

        @media screen and (max-width: 767px) {
          display: none;
        }
      }
    }

    h1 {
      color: rgba(17, 39, 63, 0.8);
      font-size: 48px;
      font-family: "SoraRegular";
      letter-spacing: -0.48px;
      line-height: 1.2;
      margin: 0;
      margin-bottom: 25px;
      text-align: left;
      text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);

      @media screen and (max-width: 991px) {
        font-size: 40px;
      }

      @media screen and (max-width: 767px) {
        font-size: 28px;
        margin-bottom: 20px;
      }

      @media screen and (max-width: 450px) {
        font-size: 24px
      }

      b {
        color: rgba(17, 39, 63, 1);
        font-family: "SoraBold";
      }

      &:before {
        background: #c2cbd4;
        border-radius: 2.5px;
        box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.14);
        content: '';
        display: block;
        height: 6px;
        margin: 0 0 35px;
        width: 85px;

        @media screen and (max-width: 767px) {
          height: 4px;
          margin: 0 0 25px;
          width: 60px;
        }
      }
    }

    h3 {
      color: rgba(17, 39, 63, 0.5);
      font-family: "MontserratRegular";
      font-size: 18px;
      letter-spacing: -0.18px;
      line-height: 1.44;
      margin: 0;
      margin-bottom: 35px;
      text-align: left;
      text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);

      @media screen and (max-width: 767px) {
        font-size: 16px;
        margin-bottom: 25px;
        max-width: 425px;
      }

      @media screen and (max-width: 450px) {
        font-size: 14px;
      }
    }

    .minting-instructions-steps {
      .item {
        align-items: center;
        display: flex;
        margin-bottom: 16px;

        .item-number {
          align-items: center;
          background: #cfd9dd;
          border-radius: 50%;
          color: #11273f;
          display: flex;
          font-family: "SoraMedium";
          font-size: 12px;
          height: 20px;
          justify-content: center;
          margin-right: 20px;
          text-align: center;
          width: 20px;
        }

        .item-description {
          color: #11273f;
          font-size: 14px;
          letter-spacing: -0.14px;
          line-height: 1.4;
        }
      }
    }

    .form-wrapper {
      background: #fff;
      border: 1px solid #cfd9dd;
      border-radius: 10px;
      box-shadow: 0px 2px 5.9px 0.1px rgba(0, 0, 0, 0.1);
      padding: 100px 65px 40px;
      position: relative;

      @media screen and (max-width: 1200px) {
        padding: 100px 30px 40px;
      }

      @media screen and (max-width: 767px) {
        margin: 0 auto;
        max-width: 358px;
        padding: 80px 30px 40px;
        width: calc(100% - 60px);
      }

      .form-logo-wrapper {
        align-items: center;
        background: #fff;
        border: 1px solid #cfd9dd;
        border-radius: 50%;
        display: flex;
        height: 130px;
        justify-content: center;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        top: -65px;
        width: 130px;

        @media screen and (max-width: 767px) {
          height: 100px;
          top: -50px;
          width: 100px;
        }

        img {
          height: 85px;
          width: auto;

          @media screen and (max-width: 767px) {
            height: 60px;
          }
        }
      }

      .form-header {
        color: rgba(17, 39, 63, 0.9);
        font-family: "SoraBold";
        font-size: 30px;
        letter-spacing: -0.3px;
        margin-bottom: 45px;
        text-align: center;
        text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);

        @media screen and (max-width: 767px) {
          font-size: 24px;
          margin-bottom: 30px;
        }
      }

      .input-wrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        label {
          color: rgba(17, 39, 63, 0.9);
          font-family: "SoraMedium";
          font-size: 16px;
          letter-spacing: -0.16px;
          margin-bottom: 6px;
          text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);

          @media screen and (max-width: 767px) {
            font-size: 14px;
          }
        }

        input {
          background: #fff;
          border-radius: 4px;
          border: 1px solid #d5d5d5;
          color: #11273f;
          font-size: 14px;
          letter-spacing: -0.14px;
          padding: 15px 25px;

          @media screen and (max-width: 767px) {
            font-size: 12px;
          }

          &:focus,
          &:focus-visible {
            border: 1px solid rgba(17, 39, 63, 0.2);
            box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.1);
            outline: none;
            transition: 0.3s;
          }

          &::placeholder {
            opacity: 0.8;
          }
        }

        .basic-multi-select {
          background: #fff;
          border-radius: 4px;
          color: #11273f;
          font-size: 14px;
          letter-spacing: -0.14px;
          .select__input {
            min-height: 36px;
          }
          .select__placeholder {
            margin-left: 10px;
          }
          .select__value-container {
            padding: 2px 15px;
          }
        }

        .password-field-wrapper {
          position: relative;

          input {
            padding-right: 60px;
            width: calc(100% - 87px);
          }

          svg {
            color: #11273f;
            font-size: 20px;
            opacity: 0.6;
            position: absolute;
            right: 25px;
            top: 50%;
            transform: translateY(-50%);
            transition: 0.3s;

            &:hover {
              cursor: pointer;
              opacity: 1;
              transition: 0.3s;
            }
          }
        }
      }

      .forgot-password-wrapper {
        display: flex;
        justify-content: flex-end;
        margin: 15px 0;

        a {
          color: #16375b;
          font-size: 14px;
          font-family: "SoraMedium";
          transition: 0.3s;
          text-decoration: none;

          @media screen and (max-width: 767px) {
            font-size: 12px;
          }

          &:hover {
            color: #475b71;
            transition: 0.3s;
          }
        }
      }

      button {
        background: #16375b;
        border: none;
        border-radius: 4px;
        box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.14);
        color: rgba(255, 255, 255, 0.9);
        display: flex;
        font-family: "SoraBold";
        font-size: 14px;
        justify-content: center;
        padding: 15px 25px;
        text-align: center;
        transition: 0.3s;
        width: 100%;

        &:hover {
          background: #475b71;
          cursor: pointer;
          transition: 0.3s;
        }

        @media screen and (max-width: 767px) {
          font-size: 12px;
        }

        &:disabled {
          background: #cfd2d6;
          cursor: default;
        }

        &.orcid-button {
          align-items: center;
          background-color: #fff;
          border: 1px solid #d3d3d3;
          color: #494A4C;
          display: flex;
          gap: 0.6em;
          justify-content: center;
          margin: 1em auto;
          max-height: 48px;

          &:hover{
            background-color: #efefef;
          }

          img{
            height: 25px;
            width: 25px;
          }
      }
      }

      .form-footer {
        align-items: center;
        color: rgba(17, 39, 63, 0.75);
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        justify-content: center;
        letter-spacing: 0.3px;
        line-height: 1.5;
        margin-top: 20px;
        text-align: center;

        a {
          color: #16375b;
          font-family: "SoraMedium";
          transition: 0.3s;
          text-decoration: none;

          &:hover {
            color: #475b71;
            transition: 0.3s;
          }
        }

        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }
    }

    .form-notice {
      margin: 25px auto;

      .form-notice-content-wrapper {
        color: #16375b;
        font-size: 12px;
        letter-spacing: -0.12px;
        line-height: 1.5;
        max-width: 320px;
        text-align: center;

        span {
          font-family: "SoraBold";
          transition: 0.3s;

          &:hover {
            cursor: pointer;
            opacity: 0.8;
            transition: 0.3s;
          }
        }
      }
    }

    .graphics-wrapper {
      .logo-graphic {
        opacity: 0.15;
        position: absolute;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        z-index: 0;

        &.left {
          animation: leftFloat 6s ease-in-out infinite;
          left: -140px;

          @media screen and (max-width: 1200px) {
            left: -70px;
          }
        }

        &.right {
          animation: rightFloat 6s ease-in-out infinite;
          right: -140px;

          @media screen and (max-width: 1200px) {
            right: -70px;
          }
        }

        @media screen and (max-width: 767px) {
          display: none;
        }

        img {
          height: auto;
          width: 280px;

          @media screen and (max-width: 1200px) {
            width: 140px;
          }
        }

        @keyframes leftFloat {
          0% {
            transform: translate3d(0, -50%, 0);
          }
          50% {
            top: 50%;
            transform: translate3d(-30px, -50%, 0);
          }
          100% {
            top: 50%;
            transform: translate3d(0, -50%, 0);
          }
        }

        @keyframes rightFloat {
          0% {
            transform: translate3d(0, -50%, 0);
          }
          50% {
            top: 50%;
            transform: translate3d(30px, -50%, 0);
          }
          100% {
            top: 50%;
            transform: translate3d(0, -50%, 0);
          }
        }
      }

      .circle-graphic {
        border-radius: 50%;
        position: absolute;
        z-index: 0;

        &.gr-1 {
          animation: gr1Float 6s ease-in-out infinite;
          background: linear-gradient(to bottom, #16375b, #fff);
          bottom: -260px;
          height: 420px;
          left: 8%;
          opacity: 0.04;
          width: 420px;

          @media screen and (max-width: 1440px) {
            bottom: -120px;
            height: 240px;
            width: 240px;
          }

          @media screen and (max-width: 500px) {
            bottom: -220px;
            height: 300px;
            left: -60px;
            width: 300px;
          }
        }

        &.gr-2 {
          animation: gr2Float 8s ease-in-out infinite;
          background: linear-gradient(to bottom, #16375b, #fff);
          bottom: -470px;
          height: 620px;
          left: 22%;
          opacity: 0.06;
          width: 620px;

          @media screen and (max-width: 1440px) {
            bottom: -150px;
            height: 300px;
            width: 300px;
          }

          @media screen and (max-width: 767px) {
            left: 40%
          }

          @media screen and (max-width: 500px) {
            bottom: -80px;
            height: 150px;
            left: calc(100% - 75px);
            width: 150px;
          }
        }

        &.gr-3 {
          animation: gr3Float 8s ease-in-out infinite;
          background: linear-gradient(to bottom, #16375b, #fff);
          height: 670px;
          right: -2%;
          opacity: 0.12;
          top: -60%;
          width: 670px;

          @media screen and (max-width: 767px) {
            right: -20%
          }
        }

        &.gr-4 {
          animation: gr4Float 6s ease-in-out infinite;
          background: linear-gradient(to bottom, #16375b, #fff);
          bottom: -37%;
          height: 420px;
          right: 10%;
          opacity: 0.04;
          width: 420px;

          @media screen and (max-width: 1440px) {
            bottom: -120px;
            height: 240px;
            right: 50px;
            width: 240px;
          }

          @media screen and (max-width: 767px) {
            display: none;
          }
        }

        @keyframes gr1Float {
          0% {
            opacity: 0.04;
            transform: translateY(0px);
          }
          50% {
            opacity: 0.06;
            transform: translate3d(10px, -40px, 0);
          }
          100% {
            opacity: 0.04;
            transform: translateY(0px);
          }
        }

        @keyframes gr2Float {
          0% {
            opacity: 0.06;
            transform: translateY(0px);
          }
          50% {
            opacity: 0.1;
            transform: translate3d(-10px, 40px, 0);
          }
          100% {
            opacity: 0.06;
            transform: translateY(0px);
          }
        }

        @keyframes gr3Float {
          0% {
            opacity: 0.06;
            transform: translateY(0px);
          }
          50% {
            opacity: 0.1;
            transform: translate3d(-10px, 40px, 0);
          }
          100% {
            opacity: 0.06;
            transform: translateY(0px);
          }
        }

        @keyframes gr4Float {
          0% {
            opacity: 0.04;
            transform: translateY(0px);
          }
          50% {
            opacity: 0.06;
            transform: translate3d(10px, -40px, 0);
          }
          100% {
            opacity: 0.04;
            transform: translateY(0px);
          }
        }
      }
    }
  }
}

.forgot-password-page {
  .content-wrapper {
    .column {
      &:first-child {
        @media screen and (max-width: 767px) {
          display: flex !important;
          margin: 0;
        }
      }
    }
  }

  .form-wrapper {
    margin: 0 auto;
    max-width: 600px;

    .form-header {
      margin-bottom: 16px !important;
    }

    .form-subheader {
      color: #11273f;
      display: flex;
      font-size: 14px;
      justify-content: center;
      line-height: 1.4;
      margin: 0 auto 30px;
      max-width: 400px;
      text-align: center;

      @media screen and (max-width: 767px) {
        font-size: 12px;
        max-width: 300px;
      }
    }
  }
}