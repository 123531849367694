@font-face {
  font-family: "SoraRegular";
  src: local("SoraRegular"),
   url("./fonts/Sora/Sora-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "SoraMedium";
  src: local("SoraMedium"),
   url("./fonts/Sora/Sora-Medium.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "SoraBold";
  src: local("SoraBold"),
   url("./fonts/Sora/Sora-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "MontserratRegular";
  src: local("MontserratRegular"),
   url("./fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "MontserratBold";
  src: local("MontserratBold"),
   url("./fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
}

html.no-scroll {
  overflow: hidden;
}

body {
  margin: 0;
  font-family: 'SoraRegular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-content {
  display: flex;
  flex-direction: column;
  margin-top: 90px;
  min-height: calc(100vh - 90px);
}
