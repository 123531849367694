.slider-container {
  margin: 0 auto;
  padding: 6px 0 32px;
  position: relative;
  width: 205px;

  .slider {
    position: relative;
    width: 205px;

    .slider__track,
    .slider__range,
    .slider__left-value,
    .slider__right-value {
      position: absolute;
    }

    .slider__track,
    .slider__range {
      border-radius: 3px;
      height: 3px;
      top: 2px;
    }

    .slider__track {
      background-color: #ced4da;
      width: 100%;
      z-index: 1;
    }

    .slider__range {
      background-color: #16375b;
      z-index: 2;
    }

    .slider__left-value,
    .slider__right-value {
      color: #16375b;
      font-size: 12px;
      margin-top: 20px;
      opacity: 0.4;

      span {
        font-size: 8px;
      }
    }

    .slider__left-value {
      left: 6px;
    }

    .slider__right-value {
      right: -4px;
    }
  }

  /* Removing the default appearance */
  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }

  .thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 205px;
    outline: none;

    &.thumb--zindex-3 {
      left: -3px;
      z-index: 3;
    }

    &.thumb--zindex-4 {
      z-index: 4;
    }

    &.thumb--zindex-5 {
      z-index: 5;
    }

    /* For Chrome browsers */
    &::-webkit-slider-thumb {
      background-color: #f1f5f7;
      border: 2px solid #16375b;
      border-radius: 50%;
      cursor: pointer;
      height: 18px;
      width: 18px;
      margin-top: 4px;
      pointer-events: all;
      position: relative;
    }

    /* For Firefox browsers */
    &::-moz-range-thumb {
      background-color: #f1f5f7;
      border: 2px solid #16375b;
      border-radius: 50%;
      cursor: pointer;
      height: 18px;
      width: 18px;
      margin-top: 4px;
      pointer-events: all;
      position: relative;
    }
  }
}